import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Auth from "../../Auth/Auth";
import { Toast } from "../../shared/toaster/Toaster";
import { post, handleError } from "../../shared/HttpReq";

import Spinner from "../../shared/spinner/Spinner";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import moment from "moment";
import guess from "moment-timezone";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "../../assets/css/stripe.css";

const StripeForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [disableBtn, updateDisableBtn] = useState(false);
  // TOASTER STATE
  const [toaster, SetToaster] = useState({
    show: false,
    type: "", // type should be "danger" or "success"
    content: "",
  });

  const route = useHistory();
  const [isLoading, setLoading] = useState(false);

  // ------- summer-program-subscription----------------
  let timezone = guess?.tz?.guess();
  let completeTime = timezone;

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateDisableBtn(true);
    const { error, token } = await stripe.createToken(
      elements.getElement(CardElement),
      { currency: "usd" }
    );

    if (error) {
      updateDisableBtn(false);
      console.log("[error]", error);
      SetToaster({
        show: true,
        type: "danger", // type should be "danger" or "success"
        content: error.message,
      });
      setTimeout(() => {
        SetToaster({
          show: false,
          type: "",
          content: "",
        });
      }, 3000);
      updateDisableBtn(false);
      setLoading(false);
    } else {
      const promo = props.convertedPromo;
      const paid_amount = props.fixedprice;
      // let res = await handleError(await fetch(`${process.env.REACT_APP_BASE_URL}/summer-program-subscription`, await post({

      let res = await handleError(
        await fetch(
          `${process.env.REACT_APP_BASE_URL}/user/membership`,
          await post({
            type: "INDIVIDUAL",
            amount_paid: paid_amount,
            coupon_code: promo,
            iap_id: 9,
            payment_mode: "STRIPE",
            token: token.id,
            time_zone: completeTime,
            // price: 99,1641554677,1673090677
            // token: token.id,
          })
        )
      );

      if (res.status === 200) {
        updateDisableBtn(false);
        SetToaster({
          show: true,
          type: "success", // type should be "danger" or "success"
          content: res.message,
        });
        const startDate = moment(res.data.current_period_start * 1000).format(
          "DD-MM-YYYY"
        );
        const endDate = moment(res.data.current_period_end * 1000).format(
          "DD-MM-YYYY"
        );

        props.setSubscriptionstartDate(startDate);
        props.setSubscriptionendDate(endDate);

        let user = Auth.user();
        user.is_summer_program_subscribed = 1;
        localStorage.setItem("user", JSON.stringify(user));
        props.setShowStripe(false);
        props.setShowSuccessModal(true);
      } else if (res.status === 422) {
        //  console.log('error:', res);
        updateDisableBtn(false);
        toast.error(res.message, 1000);
        // SetToaster({
        //     show: true,
        //     type: "danger", // type should be "danger" or "success"
        //     content: res.message,
        // });
      } else {
        // toast.error("PromoCode Error", 1000)
        SetToaster({
          show: true,
          type: "danger", // type should be "danger" or "success"
          content: res.message,
        });
      }
      setTimeout(() => {
        SetToaster({
          show: false,
          type: "",
          content: "",
        });
      }, 3000);
      updateDisableBtn(false);
      setLoading(false);
    }
  };

  const closeModal = (e) => {
    props.setShowStripe(false);
  };

  return (
    <>
      {isLoading ? <Spinner /> : null}
      <Toast
        show={toaster.show}
        content={toaster.content}
        type={toaster.type}
        onClose={() => {
          SetToaster({
            show: false,
            type: "",
            content: "",
          });
        }}
      />
      <div>
        <button
          type="button"
          className="close closeaBtnStripe"
          onClick={closeModal}
        >
          &times;
        </button>
      </div>
      <div className="text-center mt-3">
        <h4 className="">Card Details</h4>
      </div>
      <form className="stripe mt-4" onSubmit={handleSubmit}>
        <CardElement options={{ hidePostalCode: true }} />
        <div className="text-center mb-3">
          <button
            type="submit"
            className="text-center"
            disabled={!stripe}
            // disabled={disableBtn}
          >
            Pay $ {props.fixedprice}
          </button>
        </div>
      </form>
    </>
  );
};

export default StripeForm;
