import React from 'react'
import Header from '../../../shared/header/Header'
import Footer from '../../../shared/footer/Fotter'
import PhscoIMG from '../../../assets/img/physo-sec.png'
import OurStory2IMG from '../../../assets/img/ourStory2.png'
import BG from '../../../assets/img/OurStoryBanner.png'
import { Link } from 'react-router-dom'
function OurStory() {
    return (
        <>
            <section className="sec-1 our-team-main">
                <div className="container-fluid">
                    <Header />
                    {/* <div className="d-flex justify-content-end hd-regs">
                        <button className="btn btn-member registerBtn ">Register <i className="fa fa-angle-right"></i></button>
                    </div> */}

                    <div className="row container">
                        {/* <img src={BG} alt="" /> */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="sec1-lt">
                                <h2>Our Story</h2>
                                <div className="link_nav_con">
                                    <ul className="d-flex align-items-center">
                                        <li><Link to="/">Home </Link></li>
                                        <li><Link to="/">About Us</Link></li>

                                        <li><a className="active_hero">Our Story</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="sec1-rt"> <img src={SliderIMG1} alt="" className="img-fluid" /> </div>
						</div> */}
                    </div>
                </div>
            </section>
            <section className="ourPhilosophy ourstory">
                <div className="container">
                    <div className="col-6">
                        <span className="text-philoso">
                            <p>
                                Like most players and coaches, our journey with the game began in backyards, and school yards, and has taken us all over the world. We’re excited to share that journey with you, through our story, our program, and our collective pursuit of excellence.
                            </p>
                            <p>
                                <span>Our history is an expression of our philosophy;</span> both its discovery, development, and application. Every aspect of our program is reflective of the people who have built it, experienced it, taught it, and now share it.
                            </p>
                            <p>
                                The development of our technical training curriculum originated with Rob Jr.’s introduction to the game of soccer in the late 1980s. A traditional American three-sport athlete, who excelled in basketball (both as a player and coach), Rob sought the best training models and instructional offerings to help his kids improve. But the lack of a robust technical curriculum became evident. Even consulting with some of the best college coaches in the game, Rob identified the need for something more detailed and game-like. So he methodically studied and developed (in the solitude of the back yard) a model that cultivates, refines, and <span>advances soccer skill development.</span> As his curriculum took shape Rob gathered a client base that would include some of the most gifted players in the country, including pros in Europe, the U.S., and numerous D1-D3 college standouts.
                            </p>
                        </span>
                    </div>
                    <div className="col-6">
                        <img src={PhscoIMG} alt="Pyscho IMG" />
                    </div>
                </div>
                <div className="container">

                    <div className="col-12">
                        <span className="text-philoso">
                            <p>
                                <span>Our physical training methodology</span> originated with Steve Myrland’s in depth study of athletic movement and performance going back to his training as a boxer and work as a collegiate strength and conditioning coach. Supporting the growth of college, pro, and Olympic athletes since the late 1980s, Steve has been a part of the revitalization of <span>functional and movement-based training</span> for decades. Working against the “bigger is better” mentality of American weight room culture, Steve’s creative approach to athletic training is rooted in the belief that <span>simplicity yields complexity. </span>Over the years, his commitment to a deeper study of the human body has led to the development of numerous educational and equipment innovations. This includes the world renowned and patented ABC (Agility/Balance/Coordination) Ladder and the Smart Hurdle System.
                            </p>
                            <p>
                                <span>Steve and Rob met in 1995</span> when both were working the University of Wisconsin Men’s soccer team that won the NCAA Championship. The recognition of a shared philosophical foundation led to a fast friendship that has grown over the years.
                            </p>
                            <p>
                                Being the benefactor of what both have taught, <span>Robert’s synthesis</span> of their respective offerings was at first a product of intuition. But when <span>Drew Conner</span> asked him to help prepare for his first MLS season, the formation of our integrated approach truly began. As a former player—who used the same predictable weight room regiments for most of his development years—Robert looked back over hours of wasted time that may have added mass to his body: but didn’t improve his game, and probably contributed to nagging injuries. It was during his sophomore year of college that Steve stepped in to offer Robert a structured approach to physical training that completely transformed his perspective on how to prepare for the unique challenges soccer presents every athlete. From then on, Robert adopted and further advanced Steve’s adaptable and efficient method in soccer training, using simple tools and creative module to address the complex realities of the body and the game.
                            </p>
                            <p>
                                Since their first meeting, Drew has continued to use the Winning Ways method in his seven years as a pro in the States and Europe. Now a co-director of our program, Drew has a unique perspective on how to integrate the Winning Ways model at every level of youth, club, college, and pro development.
                            </p>
                            <p>
                                From humble back yard, and boxing gym, beginnings, <span>this synthesis</span> of <span>total technical training</span> and <span>complete athletic development</span> has further advanced, reaching more club, college, and pro players who have experienced the transformation in their game and in themselves. We invite you to experience it to, and become a part of the Winning Ways Training story!
                            </p>

                        </span>
                    </div>
                </div>
                <div className="container">
                    <div className="col-6">
                        <img src={OurStory2IMG} alt="Our Story" />
                    </div>
                    <div className="col-6">
                        <span className="text-philoso">
                            <p>
                                Since their first meeting, Drew has continued to use the Winning Ways method in his seven years as a pro in the States and Europe. Now a co-director of our program, Drew has a unique perspective on how to integrate the Winning Ways model at every level of youth, club, college, and pro development.
                            </p>
                            <p>
                                From humble back yard, and boxing gym, beginnings, this synthesis of total technical training and complete athletic development has further advanced, reaching more club, college, and pro players who have experienced the transformation in their game and in themselves. We invite you to experience it to, and become a part of the Winning Ways Training story!
                            </p>
                        </span>
                    </div>
                </div>
            </section>
            <section class="membrshp text-center">
                <h3 class="sec-hd">Membership</h3>
                <p class="cnt">Become a member today to gain exclusive access to our training video library.</p>
                <Link to="/Membership">  <button class="btn btn-member">Subscribe Membership <i class="fa fa-angle-right"></i></button></Link>
            </section>
            <Footer />
        </>
    )
}

export default OurStory
