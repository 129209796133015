import React, { useEffect } from 'react'
import Header from '../../../shared/header/Header'
import Fotter from '../../../shared/footer/Fotter'
import RobertIMG from '../../../assets/img/Robert-Team.png'
import RobkehoeIMG from '../../../assets/img/RobKehoe.png'
import SteveMyrlandIMG from '../../../assets/img/SteveMyrland.png'
import HannahDavisonIMG from '../../../assets/img/HannahDavison.png'
import DetailmemIMG from '../../../assets/img/Detail-member-IMG.png'
import MickSample from '../../../assets/img/Mick-Sample.jpg'
import { Link } from 'react-router-dom'

function RobKehoe() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className="sec-1 single_member_bg">
                <div className="container-fluid">
                    <Header />
                    {/* <div className="d-flex justify-content-end hd-regs">
                        <button className="btn btn-member registerBtn ">Register <i className="fa fa-angle-right"></i></button>
                    </div> */}

                    <div className="row container">
                        {/* <img src={SliderIMG1} alt="" /> */}
                        <div className=" col-sm-12 col-xs-12">
                            <div className="sec1-lt">
                                <h2>Rob Kehoe Jr.</h2>
                                <div className="link_nav_con">
                                    <ul className="d-flex align-items-center">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/">About Us</Link></li>
                                        <li><Link to="/ourTeam">Our Team</Link></li>
                                        <li><a className="active_hero">Rob Kehoe Jr.</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="sec1-rt"> <img src={SliderIMG1} alt="" className="img-fluid" /> </div>
						</div> */}
                    </div>
                </div>
            </section>
            <section className="mem-details">
                <div className="detail-view container">
                    <div className="col-6">
                        <h5>Founding Partner/President</h5>
                        <p>Rob Kehoe Jr. developed the Winning Ways technical training curriculum after being introduced to the game of soccer in the late 1980s. Prior to that Rob was a traditional American three-sport athlete, excelling in baseball, basketball, and football (both as a player and coach). Seeking the best training models and instructional offerings to help his kids improve in soccer, the lack of a robust technical curriculum became evident, even consulting with some of the most recognized college coaches in the game. In the next several years he methodically studied the game and began developing what would become a codified and progressive curriculum that provided foundational, refined, and applicable training other models lacked. As his curriculum took shape Rob gathered a client base, which would eventually include scholarship athletes at some of the best college programs in the country, and pros in both the States and Europe. He is most known for his Winning Ways leadership trainings, which has complemented to efforts of numerous Olympic, pro, and college individuals and teams. He also serves the United Soccer Coaches, where he has been the Director of College Programs since 2008. </p>
                    </div>
                    <div className="col-6">
                        <div className="detail-img">
                            <img src={RobkehoeIMG} alt="Member Img" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="team-members">
                <div className="hd">
                    <h3 className="sec-hd text-center">
                        Our Team
                    </h3>
                </div>
                <div className="team-items container">

                    <div className="row">
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={RobertIMG} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Robert L. Kehoe III</h5>
                                    <p className="card-text">Founding Partner/CEO</p>
                                    <Link to="/SingleMember" className="btn ">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={MickSample} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Mick Maley</h5>
                                    <p className="card-text">Director of Operations</p>
                                    <Link to="/MickMaley" className="btn ">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={SteveMyrlandIMG} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Steve Myrland</h5>
                                    <p className="card-text">Founding Partner/Senior Advisor</p>
                                    <Link to="/SteveMyrland" className="btn">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section class="membrshp text-center">
                <h3 class="sec-hd">Membership</h3>
                <p class="cnt">Become a member today to gain exclusive access to our training video library.</p>
                <Link to="/Membership">  <button class="btn btn-member">Subscribe Membership <i class="fa fa-angle-right"></i></button></Link>
            </section>
            <Fotter />
        </>
    )
}

export default RobKehoe
