import Auth from "../Auth/Auth";

function getToken() {
  const token = localStorage.getItem("user_token");
  if (token) {
    return {
      Authorization: `${localStorage.getItem("user_token")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }
}

async function get(body) {
  return {
    headers: await getToken(),
    method: "GET",
  };
}
async function post(data) {
  return {
    headers: await getToken(),
    method: "POST",
    body: JSON.stringify(data),
  };
}
async function put(data) {
  return {
    headers: await getToken(),
    method: "PUT",
    body: JSON.stringify(data),
  };
}
async function remove() {
  return {
    headers: await getToken(),
    method: "DELETE",
  };
}
async function handleError(data) {
  let status = data.status;
  if (data.status === 401) {
    data = await data.json();
    Auth.logout();
    window.location.replace("/");
    return {
      status: status || 500,
      message: data.error_description || "Internal Server Error",
      errors: data.errors || "Internal Server Error",
    };
  }
  if (data.status === 400 || (data.status > 401 && data.status <= 500)) {
    data = await data.json();
    return {
      status: status || 500,
      message: data.error_description || "Internal Server Error",
      errors: data.errors || "Internal Server Error",
    };
  } else {
    let res = await data.json();
    res.status = status;
    return res;
  }
}

export { get, put, post, remove, handleError };
