import React from 'react'
import Header from '../../../shared/header/Header'
import Footer from '../../../shared/footer/Fotter'
import Phsco1IMG from '../../../assets/img/physco1.png'
import Phsco2IMG from '../../../assets/img/phsco2.png'
import Dropdown from 'react-bootstrap/Dropdown'
import Navbar from 'react-bootstrap/Navbar'
import { Link } from 'react-router-dom'

function OurPhilosophy() {
    return (
        <>
            <section className="sec-1 our-team-main">
                <div className="container-fluid">
                    <Header />

                    {/* <div className="d-flex justify-content-end hd-regs">
                        <button className="btn btn-member registerBtn ">Register <i className="fa fa-angle-right"></i></button>
                    </div> */}

                    <div className="row container">
                        {/* <img src={SliderIMG1} alt="" /> */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="sec1-lt">
                                <h2>Our Philosophy</h2>
                                <div className="link_nav_con">
                                    <ul className="d-flex align-items-center">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/">About Us</Link></li>

                                        <li><a className="active_hero">Our Philosophy</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="sec1-rt"> <img src={SliderIMG1} alt="" className="img-fluid" /> </div>
						</div> */}
                    </div>
                </div>
            </section>
            <section className="ourPhilosophy">
                <div className="container">
                    <div className="col-6">
                        <span className="text-philoso">
                            <p>
                                Proven over decades of development and application, the Winning Ways Training program provides every player with what they need to train with confidence and thrive at the highest levels of competition.
                            </p>
                            <p>
                                <span>Soccer is an unpredictable movement puzzle. </span>The Winning Ways Training program is designed to help you solve it.
                            </p>
                            <p>
                                Our technical curriculum is designed to give players with the foundational, functional, and then <span>refined skill </span> required to perform with <span>advanced play-making ability</span> and <span>situational awareness.</span> Over time players will cultivate the kinetic and synaptic reflex needed to compete with increased <span>confidence </span>and <span>creativity.</span>
                            </p>
                            <p>
                                <span>Soccer is a speed-endurance sport.</span> Our physical training curriculum is designed provide individuals with athletic qualities in a robust physical package (speed, power, reaction-response skills, agility, quickness, balance). By teaching players to move and organize their bodies in three planes of motion, cultivating <span>connected strength</span> that flows from toes to fingers<span> through the core,</span> we introduce predictably-unpredictable soccer-related elements so players are better equipped to thrive in the many unpredictable moments the game presents.
                            </p>

                        </span>
                    </div>
                    <div className="col-6">
                        <img src={Phsco1IMG} alt="Pyscho IMG" />
                    </div>
                </div>
                <div className="container">
                    <div className="col-6">
                        <img src={Phsco2IMG} alt="Our Phyco" />
                    </div>
                    <div className="col-6">
                        <span className="text-philoso">
                            <p>
                                Our method equips players to be transformed into a genuinely <span>adaptable</span> rather than wholly <span>adapted</span> athlete. All the creative joy and aspiration of the game is present in our method. Where some programs boast of training kids like pros, we <span>train kids and pros like kids.</span>
                            </p>
                            <p>
                                The result is a well-trained and well-conditioned soccer player who’s ready to thrive in the competitive arena. This is what Winning Ways Training is all about.
                            </p>
                        </span>
                    </div>
                </div>

            </section>
            <section class="membrshp text-center">
                <h3 class="sec-hd">Membership</h3>
                <p class="cnt">Become a member today to gain exclusive access to our training video library.</p>
                <Link to="/Membership">  <button class="btn btn-member">Subscribe Membership <i class="fa fa-angle-right"></i></button></Link>
            </section>
            <Footer />
        </>
    )
}

export default OurPhilosophy
