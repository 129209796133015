import React, { useState } from 'react';

import axios from "axios";
// import Toast from 'light-toast';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Toast } from "../../../shared/toaster/Toaster";
import Recapture from './Recapture';

import Header from '../../../shared/header/Header';
import Fotter from '../../../shared/footer/Fotter';

// import Auth from '../../../Auth/Auth';

import CntSideIMG from '../../../assets/img/contact-us-side.png';
import TwitterIcon from '../../../assets/img/twitter-Contactus.svg';
import FacebookIcon from '../../../assets/img/facebook-Contactus.svg';
import InstagramIcon from '../../../assets/img/instagram-Contactus.svg';

import 'react-toastify/dist/ReactToastify.css';

import Spinner from "../../../shared/spinner/Spinner";

function Contactus() {


    // Regex form validation section start
    // eslint-disable-next-line
    const reEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const nameValidate = /^[aA-zZ\s]+$/;

    // Regex form validation section end

    // Form validation functionallity section start
    const [isLoading, setLoading] = useState(false);
    const [firstName, setFirstname] = useState("");
    const [lastName, setLastname] = useState("");
    const [emailName, setEmailname] = useState("");
    const [message, setMessage] = useState("");


    // TOASTER STATE
    const [toaster, SetToaster] = useState({
        show: false,
        type: "", // type should be "danger" or "success"
        content: "",
    });



    const contactData = (e) => {
        e.preventDefault()
        if (firstName === "" && lastName === "" && emailName === "" && message === "") {
            toast.error("All field is mandatory");
        }
        else if (firstName === "") {
            toast.error("Please Enter Your First Name", 1000)
        }
        else if (!nameValidate.test(firstName)) {
            toast.error("Please Enter Valid Name", 1000);
        }
        else if (lastName === "") {
            toast.error("Please Fill Last Name", 1000)
        }
        else if (!nameValidate.test(lastName)) {
            toast.error("Please enter Valid Last Name", 1000)
        }
        else if (emailName === "") {
            toast.error("Please Enter Email ID", 1000);
        }
        else if (!reEmail.test(emailName)) {
            toast.error("Please enter Valid Email", 1000);
        }
        else if (message === "") {
            toast.error("Please Enter Message", 1000);
        }

        else {
            let contactValue = { first_name: firstName, last_name: lastName, email: emailName, message: message };
            const headers = {
                Accept: "application/json",
            };
            setLoading(true);
            axios.post("http://winningwaystrainingapp.com/qa/api/contact", contactValue, { headers }).then(function (response) {
                // console.log(response);
                // console.log(response.data);
                toast.success(response.data.message);
                setFirstname("");
                setLastname("");
                setEmailname("");
                setMessage("");
                setLoading(false);
            }).catch((error) => {
                console.log(error);
                console.log(error.response);
                toast.error(error.response.data.error_description);
                setLoading(false);
            })

        }
    }
    return (
        <>
            {isLoading ? <Spinner /> : null}
            <Toast
                show={toaster.show}
                content={toaster.content}
                type={toaster.type}
                onClose={() => {
                    SetToaster({
                        show: false,
                        type: "",
                        content: "",
                    });
                }}
            />
            <section className="sec-1 our-team-main">
                <div className="container-fluid">
                    <Header />
                    {/* <div className="d-flex justify-content-end hd-regs">
                        <button className="btn btn-member registerBtn ">Register <i className="fa fa-angle-right"></i></button>
                    </div> */}

                    <div className="row container">
                        {/* <img src={SliderIMG1} alt="" /> */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="sec1-lt">
                                <h2>Contact Us</h2>
                                <div className="link_nav_con">
                                    <ul className="d-flex align-items-center">
                                        <li><Link to="/">Home</Link></li>

                                        {/* eslint-disable-next-line */}
                                        <li><a className="active_hero">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="sec1-rt"> <img src={SliderIMG1} alt="" className="img-fluid" /> </div>
						</div> */}
                    </div>
                </div>
            </section>
            <section className="contact-body">
                <div className="container con-container">
                    <div className="col-6 pl_form">
                        <span className="contact-heading">Give us a shout</span>
                        <p className="conct-text mar_top_contact">
                            Send us a note if you’d like to learn more about Winning Ways, inquire about our program, or set up a private consultation.
                        </p>
                        <div className="cntform">
                            <form onSubmit={(e) => contactData(e)} action="#">
                                <div className="name-area">

                                    <div className="first-name">
                                        <label htmlFor="">First Name <sup>&#9733;</sup></label>
                                        <input type="text" placeholder="Enter first name"
                                            autoComplete="off" value={firstName} onChange={(e) => setFirstname(e.target.value)} />
                                    </div>

                                    <div className="last-name">
                                        <label htmlFor="">Last Name<sup>&#9733;</sup></label>
                                        <input type="text" placeholder="Enter Last name" autoComplete="off" value={lastName} onChange={(e) => setLastname(e.target.value)} />
                                    </div>

                                </div>

                                <div className="email">
                                    <label htmlFor="">Email ID<sup>&#9733;</sup> </label>
                                    <input type="email" placeholder="Enter email ID" autoComplete="off" value={emailName} onChange={(e) => setEmailname(e.target.value)} />
                                </div>

                                <div className="message-area">
                                    <label htmlFor="">Message <sup>&#9733;</sup></label>
                                    <textarea type="text" placeholder="Write text…" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                </div>
                                <Recapture />

                                <div className="button-area">
                                    <button className="form-button
                                    " autoComplete="off" type="submit">Send</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-6 pr_form">
                        <div className="cont-rt">
                            <div className="cont-img">
                                <img src={CntSideIMG} alt="Contact Right Side" />
                            </div>
                            <div className="cont social">
                                <h3 className="cont-head">
                                    Follow US
                                </h3>
                                <div className="social-icons-contact">
                                    <ul>
                                        <a href="https://www.facebook.com/winning.ways.334/" target='_blank' rel="noreferrer"><li><img src={FacebookIcon} alt="Facebook Icon" /></li></a>
                                        <a href="https://www.instagram.com/winningwaystraining/?hl=en" target='_blank' rel="noreferrer"> <li><img src={InstagramIcon} alt="Insta Icon" /></li></a>
                                        <a href="https://twitter.com/w_w_training?lang=en" target='_blank' rel="noreferrer"><li><img src={TwitterIcon} alt="Twitter Icon" /></li></a>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Fotter />
            <ToastContainer />
        </>
    )
}

export default Contactus
