/* eslint-disable no-unused-vars */
import "./App.scss";

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
} from "react-router-dom";
import Homepage from "./components/guest/homepage";
import Dashboard from "./components/main/dashboard";
import Header from "./shared/header/Header";
import OurTeam from "./components/main/ourTeam/OurTeam";
import SingleMember from "./components/main/ourTeam/SingleMember";
import Membership from "./components/main/Membership/Membership";
import Contactus from "./components/main/contactus/Contactus";
import OurPhilosophy from "./components/main/OurPhilosophy/OurPhilosophy";
import OurStory from "./components/main/OurStory/OurStory";
import RobKehoe from "./components/main/ourTeam/RobKehoe";
import SteveMyrland from "././components/main/ourTeam/SteveMyrland";
import DrewConner from "././components/main/ourTeam/DrewConner";
import MickMaley from "././components/main/ourTeam/MickMaley";

function App() {
  return (
    <HashRouter>
      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/header" component={Header} />
        <Route exact path="/ourTeam" component={OurTeam} />
        <Route exact path="/SingleMember" component={SingleMember} />
        <Route exact path="/RobKehoe" component={RobKehoe} />
        <Route exact path="/SteveMyrland" component={SteveMyrland} />
        <Route exact path="/DrewConner" component={DrewConner} />
        <Route exact path="/MickMaley" component={MickMaley} />
        <Route exact path="/Membership" component={Membership} />
        <Route exact path="/Contactus" component={Contactus} />
        <Route exact path="/OurPhilosophy" component={OurPhilosophy} />
        <Route exact path="/OurStory" component={OurStory} />
      </Switch>
    </HashRouter>
  );
}

export default App;
