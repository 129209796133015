


import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
// import HeaderLogo from '../../assets/img/headerLogo.png'
// import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import Auth from "../../../Auth/Auth";
import { post, handleError } from "../../../shared/HttpReq";
import { Toast } from "../../../shared/toaster/Toaster";
import Spinner from "../../../shared/spinner/Spinner";

import logo from "../../../assets/img/logo.svg";
import Modal from 'react-bootstrap/Modal';
import { useForm } from "../../../shared/useForm";

import Header from '../../../shared/header/Header'
import Fotter from '../../../shared/footer/Fotter'
import CampsIMG from "../../../assets/img/CampsSec.png";
import passwordUnView from "../../../assets/img/icon_un_view.svg";
import passwordView from "../../../assets/img/icon_view.svg";


function Membership() {

    const [showLogin, setShowLogin] = useState(false);
    const [show, setShow] = useState(false);
    const [acceptTerm, updateAcceptTerm] = useState(false);
    const [passwordType, updatePasswordType] = useState(false);

    // TOASTER STATE
    const [toaster, SetToaster] = useState({
        show: false,
        type: "", // type should be "danger" or "success"
        content: "",
    });

    let { values, errors, bindField, isValid, setValues, setErrors } = useForm({
        validations: {
            email: {
                pattern: {
                    value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                    message: "Invalid email address.",
                },
                required: {},
            },
            password: {
                minLength: {
                    value: 8,
                    message: "Password length should be 8 minimum.",
                },
                required: {},
            },
            name: {
                required: {},
            }
            // club_college: {
            // 	required: {},
            // },
            // state: {
            // 	required: {},
            // },
        },
    });

    const route = useHistory();
    const [isLoading, setLoading] = useState(false);

    const openModal = (e, type) => {
        if (Auth.isUserAuthenticated()) {
            route.push('/dashboard');
        } else {
            updatePasswordType(false);
            e.preventDefault();
            setValues({});
            setErrors({});
            if (type === 'login') {
                setShow(false);
                setShowLogin(true);
                return;
            }
            setShow(true);
            setShowLogin(false);
        }


    }

    const handleAcceptTerm = (event) => {
        const target = event.target;
        const value = target.checked ? true : false;
        updateAcceptTerm(value);
    };

    const signupUser = async (e) => {
        e.preventDefault();
        if (!acceptTerm) {
            window.confirm('Please accept terms and conditions');
            return;
        }

        setLoading(true);
        const signupData = { ...values, ...{ deviceType: 'web', deviceToken: '', accept_term: acceptTerm ? 1 : 0 } };
        let res = await handleError(await fetch(`${process.env.REACT_APP_BASE_URL}/auth/registration`, await post(signupData)));
        if (res.status === 200) {
            Auth.login(res);
            setShow(false);
            route.push('/dashboard');
            SetToaster({
                show: true,
                type: "danger", // type should be "danger" or "success"
                content: res.message,
            })
        }
        else if (res.status === 422) {
            console.log('error:', res);
            if (res.errors.email) {
                errors.email = res.errors.email;
            } if (res.errors.password) {
                errors.password = res.errors.password;
            }

            SetToaster({
                show: true,
                type: "danger", // type should be "danger" or "success"
                content: res.message,
            })

            setTimeout(() => {
                SetToaster({
                    show: false,
                    type: "",
                    content: "",
                })
            }, 3000);
        }
        else {
            SetToaster({
                show: true,
                type: "danger", // type should be "danger" or "success"
                content: res.message,
            })

            setTimeout(() => {
                SetToaster({
                    show: false,
                    type: "",
                    content: "",
                })
            }, 3000);
        }

        setLoading(false);

    }


    const signInUser = async (e) => {
        e.preventDefault();
        setLoading(true);
        const signInData = { ...values, ...{ deviceType: 'web', deviceToken: '' } };
        let res = await handleError(await fetch(`${process.env.REACT_APP_BASE_URL}/auth/login`, await post(signInData)));
        if (res.status === 200) {
            Auth.login(res);
            setShow(false);
            route.push('/dashboard');
            SetToaster({
                show: true,
                type: "danger", // type should be "danger" or "success"
                content: res.message,
            })
        }
        else if (res.status === 422) {
            console.log('error:', res);
            if (res.errors.email) {
                errors.email = res.errors.email;
            }
            if (res.errors.password) {
                errors.password = res.errors.password;
            }

            SetToaster({
                show: true,
                type: "danger", // type should be "danger" or "success"
                content: res.message,
            })

            setTimeout(() => {
                SetToaster({
                    show: false,
                    type: "",
                    content: "",
                })
            }, 3000);
        }
        else {
            SetToaster({
                show: true,
                type: "danger", // type should be "danger" or "success"
                content: res.message,
            })

            setTimeout(() => {
                SetToaster({
                    show: false,
                    type: "",
                    content: "",
                })
            }, 3000);
        }

        setLoading(false);

    }
    return (
        <>
            {isLoading ? <Spinner /> : null}
            <Toast
                show={toaster.show}
                content={toaster.content}
                type={toaster.type}
                onClose={() => {
                    SetToaster({
                        show: false,
                        type: "",
                        content: "",
                    });
                }}
            />
            <section className="sec-1 our-team-main">
                <div className="container-fluid">
                    <Header />
                    {/* <div className="d-flex justify-content-end hd-regs">
                        <button className="btn btn-member registerBtn ">Register <i className="fa fa-angle-right"></i></button>
                    </div> */}

                    <div className="row container custm-pad">
                        {/* <img src={SliderIMG1} alt="" /> */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="sec1-lt">
                                <h2>Membership</h2>
                                <div class="link_nav_con">
                                    <ul class="d-flex align-items-center">
                                        <li><a href="#/">Home</a></li>

                                        <li><a class="active_hero" >Membership</a></li>
                                    </ul></div>
                            </div>
                        </div>
                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="sec1-rt"> <img src={SliderIMG1} alt="" className="img-fluid" /> </div>
						</div> */}
                    </div>
                </div>
            </section>
            <section className="membership-body">
                <div className="container membership-area">
                    <p>
                        Start your membership today and begin transforming your game with the Winning Ways blueprint. With full access to our guided sessions, Technical Core 90 and Movement Core 90 you’ll have a personal trainer everywhere you go, with the 90 soccer and athletic movements you’ll need to become a 90 minute player. It’s like having a personal trainer everywhere you go. Start your journey with us by signing up for an annual membership.
                    </p>
                    <br />
                    {/* <div className="italic-text-area">
                        <p className="colored-text">
                            Limited time launch pricing!
                        </p>
                    </div> */}
                    <div className="membership-price">
                        <div className="price-slip">
                            <span className="mem-price">$99.99</span>
                            <span className="mem-time">Per Year</span>
                            <button className="btn btn-member " onClick={(e) => openModal(e, 'signup')}>
                                Start Training
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="trng-smpl member-page">
                <div className="container-fluid">
                    <div className="row container">
                        <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                            <div className="trng-smpl-lt">
                                <h3 className="sec-hd">Camps</h3>
                                <p className="cnt">Check our camp offerings or send us an email to set up in person training for your team or club.</p>
                                <div className="d-flex justify-content-end hd-regs">
                                    {/* <button className="btn btn-member" onClick={() => window.open("https://winningwaystraining.com/Winningways/product-category/camps/")}>Click Here <i className="fa fa-angle-right"></i></button> */}

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                            <div className="trng-smpl-rt text-center">
                                <img className="d-block w-100 img-fluid msn-vdo " src={CampsIMG} alt="" />
                                {/* <div style={{ padding: '56.25% 0 0', position: 'relative' }}><iframe src="https://player.vimeo.com/video/408285850?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} title="Advanced Open Field Dribbling + Core &amp;amp; Joint | Phase 1"></iframe></div> */}
                            </div>


                        </div>
                    </div>
                </div>
            </section>
            <Fotter />
            {/* login modal */}
            <Modal
                show={showLogin}
                onHide={() => setShowLogin(false)}
                backdrop="static"
                keyboard={false}
            >

                <Modal.Body>
                    <div className="login-form" id="login">

                        <button type="button" className="close" onClick={() => setShowLogin(false)}>&times;</button>
                        <div className="reg text-center">
                            <img src={logo} alt="" /> <br />
                            <h3>Login</h3>
                        </div>
                        <form onSubmit={signInUser}>
                            <div className="">
                                <label>  Email </label>
                                <input type="text" name="email" placeholder="Enter Email" className="reg-frm" {...bindField('email')} />
                                {errors.email && <p className="text-danger">{errors.email}</p>}

                            </div>
                            <div className="position-relative">
                                <label>  Password</label>
                                <input type={passwordType ? 'text' : 'password'} name="password" placeholder="Enter Password" className="reg-frm padRight" {...bindField('password')} />

                                <span className="viewIcon">
                                    <img className="cursor-pointer" src={passwordType ? passwordView : passwordUnView} alt="" onClick={() => updatePasswordType(!passwordType)} />
                                </span>
                            </div>
                            {errors.password && <p className="text-danger">{errors.password}</p>}

                            <button type="submit" className="btn btn-login">Login <i className="fa fa-angle-right"></i></button>
                        </form>
                        <p className="mt-2">Don’t have an account?<span className="cursor-pointer" onClick={(e) => openModal(e, 'signup')}> Register Now</span></p>
                    </div>
                </Modal.Body>

            </Modal>


            {/* <!--login form start--> */}



            {/* <!--login form end--> */}

            {/* signup modal*/}
            <Modal
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
            >

                <Modal.Body>
                    <div className="register-form">

                        <button type="button" className="close" onClick={() => setShow(false)}>x</button>
                        <div className="reg text-center">
                            <img src={logo} alt="" /> <br />
                            <h3>Register Now</h3>
                        </div>
                        <form onSubmit={signupUser} className="mt-3">
                            <div className="">
                                <label className="">Name</label>
                                <input type="text" name="name" placeholder="Enter Name" className="reg-frm" {...bindField('name')} />
                                {errors.name && <p className="text-danger mb-0">{errors.name}</p>}

                            </div>
                            <div className="">
                                <label className="">  Email </label>
                                <input type="text" name="email" placeholder="Enter Email" className="reg-frm" {...bindField('email')} />
                                {errors.email && <p className="text-danger mb-0">{errors.email}</p>}
                            </div>
                            <div className="position-relative">
                                <label className="">  Password</label>
                                <input type={passwordType ? 'text' : 'password'} name="password" placeholder="Enter Password" className="reg-frm padRight" {...bindField('password')} />


                                <span className="viewIcon">
                                    <img className="cursor-pointer" src={passwordType ? passwordView : passwordUnView} alt="" onClick={() => updatePasswordType(!passwordType)} />
                                </span>
                            </div >
                            {errors.password && <p className="text-danger mb-0">{errors.password}</p>}
                            <div className="">
                                <label className="">  Club/College</label>
                                <input type="text" name="club" placeholder="Enter Club/College" className="reg-frm" {...bindField('club_college')} />
                                {errors.club_college && <p className="text-danger mb-0">{errors.club_college}</p>}
                            </div>
                            <div className="">
                                <label className="">State </label>
                                <input type="text" name="text" placeholder="Enter State" className="reg-frm" {...bindField('state')} />
                                {errors.state && <p className="text-danger mb-0">{errors.state}</p>}
                            </div>
                            {/* <div className="">
                                <label className="">Referral Code (Optional)</label>
                                <input type="text" name="text" placeholder="Enter Referral Code" className="reg-frm" {...bindField('code')} />
                                {errors.code && <p className="text-danger mb-0">{errors.code}</p>}
                            </div> */}

                            <br />
                            <input type="checkbox" name="terms" id="terms" checked={acceptTerm === true}
                                onChange={handleAcceptTerm} /><span className="acpt"> Accept our </span><span className="cursor-pointer" onClick={() => window.open('https://winningwaystrainingapp.com/terms.html', 'Term')}>Terms & Conditions</span>
                            <br /><br />
                            <button type="submit" className="btn btn-register" disabled={!isValid()}>Register <i className="fa fa-angle-right"></i></button>
                        </form>
                        <p className="mt-2">Already a member? <span><span className="cursor-pointer" onClick={(e) => openModal(e, 'login')}>
                            Sign In</span></span></p>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Membership
