import React from "react";

import "./Spinner.css";

export default function Spinner({ text }) {
    return (
        // <div className="loader-main" >
        //     <div className='music'>
        //         <span className='line line1'></span>
        //         <span className='line line2'></span>
        //         <span className='line line3'></span>
        //         <span className='line line4'></span>
        //         <span className='line line5'></span>
        //         <p className="mt-4">Loading...</p>
        //     </div>
        // </div>

<div className="loader-main" >
<div className="lds-ellipsis"><div></div><div></div><div></div><div></div>
{/* <p className="mt-0">Loading...</p> */}
</div>

</div>
    )
}