import React, { useEffect } from 'react'
import Header from '../../../shared/header/Header'
import Fotter from '../../../shared/footer/Fotter'
import RobertIMG from '../../../assets/img/Robert-Team.png'
import RobkehoeIMG from '../../../assets/img/RobKehoe.png'
import SteveMyrlandIMG from '../../../assets/img/SteveMyrland.png'
import HannahDavisonIMG from '../../../assets/img/HannahDavison.png'
import DetailmemIMG from '../../../assets/img/Detail-member-IMG.png'
import MickSample from '../../../assets/img/Mick-Sample.jpg'
import { Link } from 'react-router-dom'

function SingleMember() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className="sec-1 single_member_bg">
                <div className="container-fluid">
                    <Header />
                    {/* <div className="d-flex justify-content-end hd-regs">
                        <button className="btn btn-member registerBtn ">Register <i className="fa fa-angle-right"></i></button>
                    </div> */}

                    <div className="row container">
                        {/* <img src={SliderIMG1} alt="" /> */}
                        <div className=" col-sm-12 col-xs-12">
                            <div className="sec1-lt">
                                <h2>Robert L. Kehoe III</h2>
                                <div className="link_nav_con">
                                    <ul className="d-flex align-items-center">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/">About Us</Link></li>
                                        <li><Link to="/ourTeam">Our Team</Link></li>
                                        <li><a className="active_hero">Robert L. Kehoe III</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="sec1-rt"> <img src={SliderIMG1} alt="" className="img-fluid" /> </div>
						</div> */}
                    </div>
                </div>
            </section>
            <section className="mem-details">
                <div className="detail-view container">
                    <div className="col-6">
                        <h5>Founding Partner/CEO</h5>
                        <p>Robert is the founder and visionary of Winning Ways Training, synthesizing the curriculum and methodology of his founding partners, and helping soccer players maximize their athletic and personal potential since his college years. He was a four-year letter winner, and all conference selection at perennial Division III power, Wheaton College (IL), playing in four NCAA tournaments, reaching the national quarterfinals in 2002. After graduating from Wheaton he was an assistant coach for the men and women at St. Olaf College in 2004, and again for the men in 2006. In recent years he’s trained and advised numerous high school, college, and pro players.
                            Robert is also the Director of Coaching for SC Wave-Madison, and a writer who received his masters degree in philosophy from Boston College. His work (on philosophy, literature, sports, and more) has been published in a variety of magazines and websites, presented at numerous college and universities, and appeared on NPR’s On Point. He lives in Madison, Wisconsin, with his wife and their four children. </p>
                    </div>
                    <div className="col-6">
                        <div className="detail-img">
                            <img src={DetailmemIMG} alt="Member Img" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="team-members">
                <div className="hd">
                    <h3 className="sec-hd text-center">
                        Our Team
                    </h3>
                </div>
                <div className="team-items container">

                    <div className="row">
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={MickSample} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Mick Maley</h5>
                                    <p className="card-text">Director of Operations</p>
                                    <Link to="/MickMaley" className="btn ">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={RobkehoeIMG} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Rob Kehoe Jr.</h5>
                                    <p className="card-text">Founding Partner/President</p>
                                    <Link to="/RobKehoe" className="btn">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={SteveMyrlandIMG} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Steve Myrland</h5>
                                    <p className="card-text">Founding Partner/Senior Advisor</p>
                                    <Link to="/SteveMyrland" className="btn">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section class="membrshp text-center">
                <h3 class="sec-hd">Membership</h3>
                <p class="cnt">Become a member today to gain exclusive access to our training video library.</p>
                <Link to="/Membership">  <button class="btn btn-member">Subscribe Membership <i class="fa fa-angle-right"></i></button></Link>
            </section>
            <Fotter />
        </>
    )
}

export default SingleMember
