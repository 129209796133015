import React from 'react'
import FooterLogo from "../../assets/img/footer-logo.png";
import { Link } from 'react-router-dom';
function Fotter() {
    return (
        <>
            <section className="footer">

                <div className="ftr-btm container">

                    <div className="footer-menu">
                        <div className="row">
                            <div className="footer-main">
                                <div className="col-4">
                                    <img src={FooterLogo} alt="Footer Logo" />
                                    <p>Winning Ways Training provides total technical<br /> training and complete athletic development.</p>
                                </div>
                                <div className="col-2">
                                    <p>Quick Links</p>
                                    <ul>
                                        <li><Link to="/"> Home</Link></li>
                                        <li><Link to="/Membership"> Membership</Link></li>
                                        {/* <li><Link to onClick={() => window.open("https://winningwaystraining.com/Winningways/product-category/camps/")}> Camps</Link></li>
                                        <li><Link to onClick={() => window.open("https://winningwaystraining.com/Winningways/product-category/shop/")}> Shop</Link></li> */}
                                        <li><Link to="/Contactus"> Contact Us</Link></li>
                                    </ul>

                                </div>
                                <div className="col-2">
                                    <p>	About Us</p>
                                    <ul>
                                        <li><Link to="/ourTeam">Our Team</Link></li>
                                        <li><Link to="/OurPhilosophy">Our Philosophy</Link></li>
                                        <li><Link to="/OurStory">Our Story</Link></li>
                                    </ul>
                                </div>
                                <div className="col-2">
                                    <p>Follow Us</p>
                                    <ul>
                                        <li><a href="https://www.facebook.com/winning.ways.334/" target='_blank'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                                <path id="facebook" d="M22,11A11,11,0,1,0,11,22c.064,0,.129,0,.193,0V13.436H8.83V10.682h2.363V8.654a3.309,3.309,0,0,1,3.532-3.631,19.2,19.2,0,0,1,2.118.107V7.588H15.4c-1.139,0-1.362.541-1.362,1.336v1.753h2.729l-.357,2.754H14.038v8.143A11,11,0,0,0,22,11Z" fill="#22b0f7" />
                                            </svg>
                                            Facebook
                                        </a></li>
                                        <li><a href="https://twitter.com/w_w_training?lang=en" target='_blank'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                                <path id="twitter" d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm5.022,8.577q.007.162.007.326a7.135,7.135,0,0,1-7.184,7.184h0a7.147,7.147,0,0,1-3.87-1.134,5.135,5.135,0,0,0,.6.035,5.066,5.066,0,0,0,3.136-1.081,2.528,2.528,0,0,1-2.359-1.754A2.516,2.516,0,0,0,7.5,12.11,2.525,2.525,0,0,1,5.47,9.635c0-.011,0-.022,0-.032a2.507,2.507,0,0,0,1.144.316,2.527,2.527,0,0,1-.782-3.371,7.168,7.168,0,0,0,5.2,2.638,2.526,2.526,0,0,1,4.3-2.3,5.064,5.064,0,0,0,1.6-.613,2.535,2.535,0,0,1-1.11,1.4,5.035,5.035,0,0,0,1.45-.4,5.131,5.131,0,0,1-1.259,1.308Zm0,0" fill="#22b0f7" />
                                            </svg>
                                            Twitter
                                        </a></li>
                                        <li><a href="https://www.instagram.com/winningwaystraining/?hl=en" target='_blank'>
                                            <svg id="instagram" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                                <path id="Path_78536" data-name="Path 78536" d="M211.261,209.13a2.13,2.13,0,1,1-2.13-2.13A2.13,2.13,0,0,1,211.261,209.13Zm0,0" transform="translate(-198.13 -198.13)" fill="#22b0f7" />
                                                <path id="Path_78537" data-name="Path 78537" d="M145.706,136.959a2.116,2.116,0,0,0-1.213-1.213,3.536,3.536,0,0,0-1.187-.22c-.674-.031-.876-.037-2.583-.037s-1.909.006-2.583.037a3.538,3.538,0,0,0-1.187.22,2.117,2.117,0,0,0-1.213,1.213,3.538,3.538,0,0,0-.22,1.187c-.031.674-.037.876-.037,2.583s.007,1.908.037,2.582a3.536,3.536,0,0,0,.22,1.187,2.116,2.116,0,0,0,1.213,1.213,3.532,3.532,0,0,0,1.187.22c.674.031.876.037,2.582.037s1.909-.006,2.583-.037a3.532,3.532,0,0,0,1.187-.22,2.116,2.116,0,0,0,1.213-1.213,3.539,3.539,0,0,0,.22-1.187c.031-.674.037-.876.037-2.582s-.006-1.909-.037-2.583A3.532,3.532,0,0,0,145.706,136.959Zm-4.982,7.051a3.282,3.282,0,1,1,3.282-3.282A3.282,3.282,0,0,1,140.724,144.01Zm3.411-5.926a.767.767,0,1,1,.767-.767A.767.767,0,0,1,144.136,138.083Zm0,0" transform="translate(-129.724 -129.728)" fill="#22b0f7" />
                                                <path id="Path_78538" data-name="Path 78538" d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm6.278,13.6a4.634,4.634,0,0,1-.294,1.533,3.23,3.23,0,0,1-1.847,1.847,4.638,4.638,0,0,1-1.533.294c-.674.031-.889.038-2.6.038s-1.931-.007-2.6-.038a4.638,4.638,0,0,1-1.533-.294,3.23,3.23,0,0,1-1.847-1.847A4.633,4.633,0,0,1,4.722,13.6c-.031-.674-.038-.889-.038-2.6s.007-1.931.038-2.6a4.635,4.635,0,0,1,.293-1.533A3.232,3.232,0,0,1,6.863,5.015,4.638,4.638,0,0,1,8.4,4.722c.674-.031.889-.038,2.6-.038s1.931.007,2.6.038a4.639,4.639,0,0,1,1.533.293,3.231,3.231,0,0,1,1.847,1.847A4.634,4.634,0,0,1,17.278,8.4c.031.674.038.889.038,2.6S17.309,12.931,17.278,13.6Zm0,0" fill="#22b0f7" />
                                            </svg>
                                            Instagram
                                        </a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                <div className="ftr-btm-lt">
                                    <p>Copyright Winning Ways Training, LLC 2021</p>
                                </div>
                            </div>
                            {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
								<div className="ftr-btm-rt">
									<div className="sm-ico"><span>Follow us on: </span>
										<a href="#"><span><i className="fa fa-facebook-f" aria-hidden="true"></i></span></a>
										<a href="#">
											<span><i className="fa fa-twitter" aria-hidden="true"></i></span>
										</a>
										<a href="#"><span><i className="fa fa-instagram" aria-hidden="true"></i></span></a>
									</div>
								</div>
							</div> */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Fotter
