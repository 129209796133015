import React, { useEffect } from 'react'
import Header from '../../../shared/header/Header'
import Fotter from '../../../shared/footer/Fotter'
import RobertIMG from '../../../assets/img/Robert-Team.png'
import RobkehoeIMG from '../../../assets/img/RobKehoe.png'
import SteveMyrlandIMG from '../../../assets/img/SteveMyrland.png'
import DrewConnerIMG from '../../../assets/img/DrewConner.png'
import HannahDavisonIMG from '../../../assets/img/HannahDavison.png'
import DetailmemIMG from '../../../assets/img/Detail-member-IMG.png'
import MickSample from '../../../assets/img/Mick-Sample.jpg'
import { Link } from 'react-router-dom'

function DrewConner() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className="sec-1 single_member_bg">
                <div className="container-fluid">
                    <Header />
                    {/* <div className="d-flex justify-content-end hd-regs">
                        <button className="btn btn-member registerBtn ">Register <i className="fa fa-angle-right"></i></button>
                    </div> */}

                    <div className="row container">
                        {/* <img src={SliderIMG1} alt="" /> */}
                        <div className=" col-sm-12 col-xs-12">
                            <div className="sec1-lt">
                                <h2>Drew Conner </h2>
                                <div className="link_nav_con">
                                    <ul className="d-flex align-items-center">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/">About Us</Link></li>
                                        <li><Link to="/ourTeam">Our Team</Link></li>
                                        <li><a className="active_hero">Drew Conner </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="sec1-rt"> <img src={SliderIMG1} alt="" className="img-fluid" /> </div>
						</div> */}
                    </div>
                </div>
            </section>
            <section className="mem-details">
                <div className="detail-view container">
                    <div className="col-6">
                        <h5>Director of Player Engagement and Communications</h5>
                        <p>A product of the Chicago Fire Academy, playing three years with their first team, Drew Conner brings experience and perspective to our team. Prior to joining the Fire he was a four year starter at the University of Wisconsin, where he first encountered the Winning Ways method shortly after completing his eligibility. Since then he has returned to, and adopted, the program philosophy and now serves as director of Player Engagement and Communications. Drew is currently a captain for the recently formed Chicago House after two years as a leading contributor with Indy Eleven.  </p>
                    </div>
                    <div className="col-6">
                        <div className="detail-img">
                            <img src={DrewConnerIMG} alt="Member Img" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="team-members">
                <div className="hd">
                    <h3 className="sec-hd text-center">
                        Our Team
                    </h3>
                </div>
                <div className="team-items container">

                    <div className="row">
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={MickSample} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Mick Maley</h5>
                                    <p className="card-text">Director of Operations</p>
                                    <Link to="/MickMaley" className="btn ">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={RobkehoeIMG} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Rob Kehoe Jr.</h5>
                                    <p className="card-text">Founding Partner/President</p>
                                    <Link to="/RobKehoe" className="btn">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={SteveMyrlandIMG} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Steve Myrland</h5>
                                    <p className="card-text">Founding Partner/Senior Advisor</p>
                                    <Link to="/SteveMyrland" className="btn">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section class="membrshp text-center">
                <h3 class="sec-hd">Membership</h3>
                <p class="cnt">Become a member today to gain exclusive access to our training video library.</p>
                <Link to="/Membership">  <button class="btn btn-member">Subscribe Membership <i class="fa fa-angle-right"></i></button></Link>
            </section>
            <Fotter />
        </>
    )
}

export default DrewConner
