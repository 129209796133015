import React, { useEffect } from 'react'
import Header from '../../../shared/header/Header'
import Fotter from '../../../shared/footer/Fotter'
import RobertIMG from '../../../assets/img/Robert-Team.png'
import RobkehoeIMG from '../../../assets/img/RobKehoe.png'
import SteveMyrlandIMG from '../../../assets/img/SteveMyrland.png'
import DrewConnerIMG from '../../../assets/img/DrewConner.png'
import HannahDavisonIMG from '../../../assets/img/HannahDavison.png'
import DetailmemIMG from '../../../assets/img/Detail-member-IMG.png'
import { Link } from 'react-router-dom'

function SteveMyrland() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className="sec-1 single_member_bg">
                <div className="container-fluid">
                    <Header />
                    {/* <div className="d-flex justify-content-end hd-regs">
                        <button className="btn btn-member registerBtn ">Register <i className="fa fa-angle-right"></i></button>
                    </div> */}

                    <div className="row container">
                        {/* <img src={SliderIMG1} alt="" /> */}
                        <div className=" col-sm-12 col-xs-12">
                            <div className="sec1-lt">
                                <h2>Steve Myrland </h2>
                                <div className="link_nav_con">
                                    <ul className="d-flex align-items-center">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/">About Us</Link></li>
                                        <li><Link to="/ourTeam">Our Team</Link></li>
                                        <li><a className="active_hero">Steve Myrland </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="sec1-rt"> <img src={SliderIMG1} alt="" className="img-fluid" /> </div>
						</div> */}
                    </div>
                </div>
            </section>
            <section className="mem-details">
                <div className="detail-view container">
                    <div className="col-6">
                        <h5>Founding Partner/Senior Advisor</h5>
                        <p>Steve Myrland began developing our physical training methodology in 1973 as a college undergrad when he took up the sport of boxing. This sparked his interest in to plumb the depths of athletic movement and performance, which has grown steadily stronger ever since. The professional part of Steve’s journey launched at the University of Wisconsin in 1988, as a strength and conditioning coach who assisted with Big-Ten and National Championship efforts in Hockey, Soccer, Cross-Country, Tennis, and Rowing. In 1993-94, he took a one-year leave-of-absence from the UW to work with the San Jose Sharks, who charted a team record for single-season improvement, boosting their league standing point-total by fifty-eight over the previous season’s record and making it to the Stanley Cup Playoffs for the first time in franchise history. Along the way he has worked with other pro teams and individual players, currently advising the Central Cross Country Skiing (CXC) Olympic Development Program in Madison, Wisconsin. Over the years, Steve’s commitment to a deeper study of the human body, and creative approach to athletic training has led to the development of numerous educational and equipment innovations. This includes the world renowned and patented ABC (Agility/Balance/Coordination) Ladder and the Smart Hurdle System. </p>
                    </div>
                    <div className="col-6">
                        <div className="detail-img">
                            <img src={SteveMyrlandIMG} alt="Member Img" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="team-members">
                <div className="hd">
                    <h3 className="sec-hd text-center">
                        Our Team
                    </h3>
                </div>
                <div className="team-items container">

                    <div className="row">
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={RobertIMG} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Robert L. Kehoe III</h5>
                                    <p className="card-text">Founding Partner/CEO</p>
                                    <Link to="/SingleMember" className="btn ">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={RobkehoeIMG} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Rob Kehoe Jr.</h5>
                                    <p className="card-text">Founding Partner/President</p>
                                    <Link to="/RobKehoe" className="btn">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card">
                                <img className="card-img-top" src={DrewConnerIMG} alt="Team Member Image" />
                                <div className="card-body">
                                    <h5 className="card-title">Drew Conner</h5>
                                    <p className="card-text">Director of Player Engagement and Communications</p>
                                    <Link to="/DrewConner" className="btn">View Detail</Link>
                                    <i class="fa fa-angle-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section class="membrshp text-center">
                <h3 class="sec-hd">Membership</h3>
                <p class="cnt">Become a member today to gain exclusive access to our training video library.</p>
                <Link to="/Membership">  <button class="btn btn-member">Subscribe Membership <i class="fa fa-angle-right"></i></button></Link>
            </section>
            <Fotter />
        </>
    )
}

export default SteveMyrland
