class Auth {
  authenticated = false;

  login(data) {
    const token = data.data.token;

    const pro_access = data.data.pro_access;

    localStorage.setItem("user_token", token);

    localStorage.setItem("pro_access", pro_access);

    const user = JSON.stringify(data.data);
    localStorage.setItem("user", user);

    if (token) {
      this.authenticated = true;
      console.log("You are logged in: " + this.authenticated);
    } else {
      this.authenticated = false;
      console.warn("Check your credentials");
    }
  }

  user() {
    let user = localStorage.getItem("user");

    return JSON.parse(user);
  }

  logout() {
    localStorage.removeItem("user_token");
    localStorage.removeItem("user");
    localStorage.removeItem("pro_access");
    localStorage.removeItem("counter_count");
    this.authenticated = false;
  }

  isUserAuthenticated() {
    if (localStorage.getItem("user_token")) {
      return true;
    } else {
      return false;
    }
  }
  isModalOpen() {
    if (localStorage.getItem("counter_count") === "true") {
      return true;
    } else {
      return false;
    }
  }
}

export default new Auth();
