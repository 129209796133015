import React from "react";
import "./Toaster.module.css";

function Toast({ type, content, show, onClose }) {
    return (
        <div className={`${show ? "toast-show" : "toast"}`}>
            <div className="toast-header">
                <strong className={`mr-auto text-${type}`}>
                    {type === "danger" ? "Error" : "Success"}
                </strong>
                {/* <small className="text-muted">11 mins ago</small> */}
                <button
                    type="button"
                    className="ml-2 mb-1 close"
                    onClick={onClose}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="toast-body">{content}</div>
        </div>
    );
}

export { Toast };
